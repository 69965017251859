import React from 'react'

import { BoundedBox as BoundedBoxBase } from 'system'

export const BoundedBox = props => (
  <BoundedBoxBase
    pb={[6, 10]}
    pl={[3, 4]}
    pr={[3, 4]}
    pt={[6, 10]}
    {...props}
  />
)
