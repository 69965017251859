import React from 'react'

export const LogoBug = props => {
  return (
    <svg
      viewBox="0 0 216.61 216.61"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m108.3 0a108.31 108.31 0 1 0 108.31 108.3 108.3 108.3 0 0 0 -108.31-108.3zm0 171.6-31.81-31.81h63.63zm48.91-48.89-48.91-48.9-48.9 48.9-14.4-14.4 63.3-63.31 63.31 63.3z"
        fill="#d6d1ca"
      />
    </svg>
  )
}
