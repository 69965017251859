import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import VisuallyHidden from '@reach/visually-hidden'
import { Button } from 'src/components/Button'
import { Box, Flex, Text } from 'system'
import { BoundedBox } from 'src/components/BoundedBox'
import { Heading } from 'src/components/Heading'
import { LogoBugImage } from 'src/components/LogoBugImage'
import { Subheading } from 'src/components/Subheading'
import { TapaPatternFloating } from 'src/components/TapaPatternFloating'
import styles from './Popup.module.css'

const usePopup = () => {
    const data = useStaticQuery(graphql`
        query Popup {
            file(relativePath: {eq: "popup.md"}) {
                modifiedTime
                childMarkdownRemark {
                    frontmatter {
                        enabled
                        heading
                        description
                        image {
                            childImageSharp {
                                fluid(maxWidth: 1000, quality: 85) {
                                    ...GatsbyImageSharpFluid_withWebp_noBase64
                                }
                            }
                        }
                        button {
                            name
                            url
                        }
                    }
                }
            }
        }
    `)

    const frontmatter = data.file.childMarkdownRemark.frontmatter

    return {
        modifiedTime: data.file.modifiedTime,
        enabled: frontmatter.enabled,
        heading: frontmatter.heading,
        description: frontmatter.description,
        button: frontmatter.button,
        image: frontmatter.image?.childImageSharp?.fluid,
    }
}


const XIcon = (props) => {
    return (
        <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
            <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.72 6.97a.75.75 0 1 0-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 1 0 1.06 1.06L12 13.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L13.06 12l1.72-1.72a.75.75 0 1 0-1.06-1.06L12 10.94l-1.72-1.72Z" clipRule="evenodd" />
        </svg>
    )
}

export const Popup = () => {
    /** @type {React.MutableRefObject<HTMLDialogElement>} */
    const rDialog = React.useRef(null)
    const popup = usePopup()
    const key = `oah-popup-${popup.modifiedTime}`

    React.useEffect(() => {
        if (!popup.enabled) return

        const dialog = rDialog.current
        const hasDismissedBefore = localStorage.getItem(key)
        if (hasDismissedBefore === 'true') return

        const onClose = () => localStorage.setItem(key, 'true')

        dialog.showModal()
        dialog.addEventListener('close', onClose)

        return () => dialog.removeEventListener('close', onClose)
    }, [popup.enabled])

    const closePopup = () => {
        localStorage.setItem(key, 'true')
        rDialog.current.close()
    }

    const closeDialogOnOutsideClick = (e) => {
        if (e.target === rDialog.current) closePopup()
    }

    return (
        <dialog ref={rDialog} className={styles.dialog} onClick={closeDialogOnOutsideClick}>
            <BoundedBox
                bg="sandLight"
                position="relative"
                pt={[4, 8]}
                pb={[3, 6]}
            >
                <button className={styles.close} onClick={closePopup}>
                    <VisuallyHidden>Close</VisuallyHidden>
                    <XIcon />
                </button>

                <TapaPatternFloating opacity={0.3} />
                <Flex
                    flexDirection={['column', 'row']}
                    justifyContent={[null, 'space-between']}
                    position="relative"
                >
                    <Box width={[1, 8 / 12]} mr={[null, 4]} mb={[4, 0]}>
                        <Subheading color="teal" mb={2}>
                            Notice
                        </Subheading>

                        {popup.heading &&
                            <Heading
                                as="h2"
                                color="white"
                                fontSize={['xlarge', 'xxlarge']}
                                fontWeight="normal"
                                fontFamily="sans"
                                lineHeight="title"
                                mb={3}
                            >
                                {popup.heading}
                            </Heading>
                        }

                        {popup.description &&
                            <Text
                                as="p"
                                color="navy"
                                fontSize={['medium', 'midLarge']}
                                fontFamily="sans"
                                lineHeight="copy"
                                mb={3}
                            >
                                {popup.description}
                            </Text>
                        }

                        {popup.button &&
                            <Button target="_blank" rel="nofollow noreferrer" fontFamily="sans" href={popup.button.url}>
                                {popup.button.name}
                            </Button>
                        }
                    </Box>

                    <Box width={[1, 4 / 12]}>
                        <LogoBugImage
                            fillInner="sandLight"
                            imageFluid={popup.image}
                            maxWidth={['15rem', 'none']}
                            mx="auto"
                        />
                    </Box>
                </Flex>
            </BoundedBox>
        </dialog>
    )
}