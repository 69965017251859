export const theme = {
  colors: {
    navy: '#0d223f',
    sand: '#d8d1ca',
    sandDark: '#988b82',
    sandExtraLight: '#f2efec',
    sandLight: '#dfd9d3',
    sky: '#477392',
    teal: '#0d586d',
    white: '#fff',
    yellow: '#f7c677',
  },
  fonts: {
    sans: "'Roboto', sans-serif",
    sansCond: "'Roboto Condensed', sans-serif",
  },
  fontSizes: {
    xtiny: '0.6rem',
    tiny: '0.8rem',
    small: '0.9rem',
    medium: '1rem',
    midLarge: '1.25rem',
    large: '2rem',
    xlarge: '2.5rem',
    xxlarge: '3rem',
    xxxlarge: '4.5rem',
    xxxxlarge: '6rem',
  },
  boxStyles: {
    everySecondNoMargin: {
      '&:nth-child(2n)': {
        marginBottom: 0,
        marginRight: 0,
      },
      '&:last-child': {
        marginBottom: 0,
        marginRight: 0,
      },
    },
  },
}
