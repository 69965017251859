import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { get, map, compose } from 'lodash/fp'
import { nodes } from 'helpers'

import { Box, Flex, Text, Link } from 'system'
import { BoundedBox } from 'src/components/BoundedBox'
import { Logo } from 'src/components/Logo'

const NavItem = ({ to, children, ...props }) => (
  <Box as="li" mb={[1, 2]} boxStyle="lastNoMargin" {...props}>
    <Link to={to} fontSize={['medium', 'midLarge']}>
      {children}
    </Link>
  </Box>
)

const LocationItem = ({ name, to, contactData, ...props }) => (
  <Box as="li" mb={[1, 2]} boxStyle="lastNoMargin" {...props}>
    <Text
      color="white"
      fontSize="midLarge"
      fontFamily="sansCond"
      textStyle="caps"
      lineHeight="title"
    >
      {name}
    </Text>
    <Link to={to} color="yellow" fontSize={['medium', 'midLarge']}>
      {contactData}
    </Link>
  </Box>
)

const render = props => queryData => {
  const settings = get('settings.childMarkdownRemark.frontmatter', queryData)
  const locations = compose(
    nodes,
    get('locations')
  )(queryData)

  return (
    <>
      <BoundedBox as="footer" bg="navy" color="white" py={[6, 8]} {...props}>
        <Flex flexDirection={['column', 'row']} mb={6}>
          <Box width={[1, 5 / 12]} mb={[4, 0]} mr={[null, 4]}>
            <Link to="/">
              <Logo
                fillBug="teal"
                fillType="white"
                maxWidth="18rem"
                width={[0.5, 1]}
              />
            </Link>
          </Box>
          <Box as="nav" width={[1, 3 / 12]} mb={[4, 0]} mr={[null, 4]}>
            <Box as="ul">
              <NavItem to="/services/">Services</NavItem>
              <NavItem to="/for-patients/">For Patients</NavItem>
              <NavItem to="/our-team/">Our Team</NavItem>
              <NavItem to="/locations/">Locations</NavItem>
              <NavItem to="/about/">About Us</NavItem>
            </Box>
            <Box width="3rem" height="2px" bg="teal" my={[3, 4]} />
            <LocationItem
              as="div"
              name="General Inquiries"
              contactData={get('general_inquiry_email', settings)}
              to={`mailto:${get('general_inquiry_email', settings)}`}
            />
            <Box
              width="3rem"
              height="2px"
              bg="teal"
              mt={[3, 4]}
              display={[null, 'none']}
            />
          </Box>
          <Box width={[1, 4 / 12]}>
            <Box as="ul">
              {map(
                location => (
                  <LocationItem
                    key={get('id', location)}
                    name={get('frontmatter.title', location)}
                    contactData={get('frontmatter.phoneNumber', location)}
                    to={`tel://${get('frontmatter.phoneNumber', location)}`}
                  />
                ),
                locations
              )}
            </Box>
          </Box>
        </Flex>
        <Text as="div" fontSize={['small', 'medium']}>
          <Flex
            alignItems="center"
            color="sky"
            flexDirection={['column', 'row']}
            justifyContent={[null, 'center']}
            mb={2}
          >
            <Text lineHeight="copy" textAlign="center" mb={[1, 0]}>
              &copy; {new Date().getFullYear()} {get('site_title', settings)}
            </Text>
            <Text display={['none', 'block']} mx={2} lineHeight="copy">
              |
            </Text>
            <Text lineHeight="copy" textAlign="center">
              Website Design &amp; Development by{' '}
              <Link
                to="https://walltowall.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Wall-to-Wall Studios
              </Link>
            </Text>
          </Flex>
          <Box as="nav">
            <Flex
              as="ul"
              alignItems="center"
              flexDirection={['column', 'row']}
              justifyContent={[null, 'center']}
            >
              <Box as="li" color="sandLight">
                <Link to="/privacy-policy/">Privacy Policy</Link>
              </Box>
            </Flex>
          </Box>
        </Text>
      </BoundedBox>
      <script
        src="//tag.brandcdn.com/autoscript/orthopedicassociatesofhawaii_vfdwuk1fnvvsvek9/Orthopedic_Associates_of_Hawaii.js"
        defer
      />
    </>
  )
}

export const Footer = props => (
  <StaticQuery
    query={graphql`
      query {
        settings: file(relativePath: { eq: "settings.md" }) {
          childMarkdownRemark {
            frontmatter {
              site_title
              general_inquiry_email
            }
          }
        }
        locations: allMarkdownRemark(
          sort: { fields: [frontmatter___order] }
          filter: { fileAbsolutePath: { regex: "/(locations)/.*.md$/" } }
        ) {
          edges {
            node {
              id
              frontmatter {
                title
                phoneNumber: phone_number
              }
            }
          }
        }
      }
    `}
    render={render(props)}
  />
)
