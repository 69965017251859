import React from 'react'

export const IconExternalLink = props => {
  return (
    <svg
      enable-background="new 0 0 24 24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m16 22h-11c-1.7 0-3-1.3-3-3v-11c0-1.7 1.3-3 3-3h6c.6 0 1 .4 1 1s-.4 1-1 1h-6c-.6 0-1 .4-1 1v11c0 .6.4 1 1 1h11c.6 0 1-.4 1-1v-6c0-.6.4-1 1-1s1 .4 1 1v6c0 1.7-1.3 3-3 3z" />
      <path d="m21 10c-.6 0-1-.4-1-1v-5h-5c-.6 0-1-.4-1-1s.4-1 1-1h6c.6 0 1 .4 1 1v6c0 .6-.4 1-1 1z" />
      <path d="m10 15c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l11-11c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-11 11c-.2.2-.4.3-.7.3z" />
    </svg>
  )
}
