import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { get, map, compose } from 'lodash/fp'
import { Location } from '@reach/router'
import { nodes, isPathActive, isInternal } from 'helpers'

import { Box, Flex, Text, Link, SVG } from 'system'
import { BoundedBox } from 'src/components/BoundedBox'
import { Heading } from 'src/components/Heading'
import { IconTriangleRight } from 'src/components/IconTriangleRight'
import { IconExternalLink } from 'src/components/IconExternalLink'

const NavItem = ({
  children,
  to,
  name,
  secondary = false,
  childColumns = 2,
  childSide = 'left',
  withActiveIndicator = true,
  ...props
}) => (
  <Location>
    {({ location }) => (
      <Box
        as="li"
        css={`
          &:hover {
            .header-nav__subnav,
            .header-nav__subnav__activeBar {
              opacity: 1;
            }

            .header-nav__subnav {
              pointer-events: auto;
            }
          }
        `}
        {...props}
      >
        <Box
          as={Link}
          to={to}
          position="relative"
          px="0.75rem"
          pb={4}
          mb={-4}
          color={secondary ? 'sandDark' : 'teal'}
          css={`
            transition: color ${p => p.theme.transition};
            &:hover {
              color: ${p => p.theme.colors.navy};
            }
          `}
        >
          <Text as="span" fontWeight="bold" textStyle="smallCaps" zIndex={2}>
            {name}
          </Text>
          {withActiveIndicator && (
            <Box
              className="header-nav__subnav__activeBar"
              height={4}
              bg="teal"
              position="absolute"
              bottom={0}
              left="0.75rem"
              right="0.75rem"
              opacity={isPathActive(to, location) ? 1 : 0}
              css={`
                transition: opacity ${p => p.theme.transition};
              `}
            />
          )}
        </Box>
        {children && (
          <BoundedBox
            className="header-nav__subnav"
            bg="teal"
            color="white"
            left={0}
            opacity={0}
            pb={4}
            position="absolute"
            pt={4}
            right={0}
            top="100%"
            zIndex={1}
            css={`
              box-shadow: 0 16px 48px rgba(0, 0, 0, 0.2),
                0 4px 8px rgba(0, 0, 0, 0.1);
              pointer-events: none;
              transition: opacity ${p => p.theme.transition};
            `}
          >
            <Flex justifyContent="space-between">
              <Heading color="yellow">{name}</Heading>
              <Box
                as="ul"
                alignSelf="center"
                flex="1 0 auto"
                maxWidth="37.625rem"
                width="100%"
                css={`
                  columns: ${childColumns};
                `}
              >
                {children}
              </Box>
            </Flex>
          </BoundedBox>
        )}
      </Box>
    )}
  </Location>
)

const ChildNavItem = ({ to, children, ...props }) => (
  <Location>
    {({ location }) => (
      <Box as="li" mb={1} position="relative" boxStyle="lastNoMargin">
        <Link
          to={to}
          lineHeight="copy"
          fontWeight="light"
          target={isInternal(to) ? undefined : '_blank'}
          rel={isInternal(to) ? undefined : 'noreferrer nofollow'}
          css={`
            &:hover {
              & + .header-nav__subnav__icon {
                opacity: 1;
              }
            }
          `}
        >
          {children}
          {!isInternal(to) && (
            <SVG
              svg={IconExternalLink}
              x={576}
              y={512}
              display="inline-block"
              fill="white"
              ml={1}
              width="1rem"
            />
          )}
        </Link>
        <SVG
          className="header-nav__subnav__icon"
          svg={IconTriangleRight}
          x={1}
          y={2}
          fill="yellow"
          left={0}
          ml={-2}
          opacity={isPathActive(to, location) ? 1 : 0}
          position="absolute"
          top="50%"
          width="0.45rem"
          css={`
            transform: translateY(-50%);
            transition: opacity ${p => p.theme.transition};
          `}
        />
      </Box>
    )}
  </Location>
)

const render = props => queryData => {
  const settings = get('settings.childMarkdownRemark.frontmatter', queryData)

  const services = compose(
    nodes,
    get('services')
  )(queryData)

  const physicians = compose(
    nodes,
    get('physicians')
  )(queryData)

  return (
    <Flex as="nav" flexDirection="column" alignItems="flex-end" {...props}>
      <Flex as="ul" mb={2}>
        <NavItem
          to={get('bill_pay_button.url', settings)}
          name="Payment Portal"
          secondary={true}
          withActiveIndicator={false}
        />
      </Flex>
      <Flex as="ul">
        <NavItem to="/services/" name="Services" childColumns={2}>
          {map(
            service => (
              <ChildNavItem
                key={get('frontmatter.path', service)}
                to={`/services/${get('frontmatter.path', service)}/`}
              >
                {get('frontmatter.title', service)}
              </ChildNavItem>
            ),
            services
          )}
        </NavItem>
        <NavItem to="/for-patients/" name="For Patients">
          <ChildNavItem to="/for-patients/insurance/">Insurance</ChildNavItem>
          <ChildNavItem to="/for-patients/resources/">Resources</ChildNavItem>
          <ChildNavItem to={get('bill_pay_button.url', settings)}>
            Bill Pay
          </ChildNavItem>
          <ChildNavItem to="/for-patients/faqs/">FAQs</ChildNavItem>
          <ChildNavItem to="/for-patients/good-faith-estimate/">
            Good Faith Estimate
          </ChildNavItem>
          <ChildNavItem to="/for-patients/no-surprises/">
            Protections Against Surprise Medical Bills
          </ChildNavItem>
        </NavItem>
        <NavItem
          to="/our-team/"
          name="Our Team"
          childColumns={2}
          childSide="right"
        >
          {map(
            physician => (
              <ChildNavItem
                key={get('frontmatter.path', physician)}
                to={`/our-team/${get('frontmatter.path', physician)}/`}
              >
                {get('frontmatter.title', physician)}
              </ChildNavItem>
            ),
            physicians
          )}
        </NavItem>
        <NavItem to="/locations/" name="Locations" />
        <NavItem to="/about/" name="About Us" childSide="right">
          <ChildNavItem to="/about/our-story/">Our Story</ChildNavItem>
          <ChildNavItem to="/about/affiliates/">Affiliates</ChildNavItem>
        </NavItem>
      </Flex>
    </Flex>
  )
}

export const Desktop = props => (
  <StaticQuery
    query={graphql`
      query {
        settings: file(relativePath: { eq: "settings.md" }) {
          childMarkdownRemark {
            frontmatter {
              bill_pay_button {
                url
                name
              }
            }
          }
        }
        services: allMarkdownRemark(
          sort: { fields: [frontmatter___order] }
          filter: { fileAbsolutePath: { regex: "/(services)/.*.md$/" } }
        ) {
          ...NavItem
        }
        physicians: allMarkdownRemark(
          sort: { fields: [frontmatter___order] }
          filter: { fileAbsolutePath: { regex: "/(physicians)/.*.md$/" } }
        ) {
          ...NavItem
        }
      }
      fragment NavItem on MarkdownRemarkConnection {
        edges {
          node {
            id
            frontmatter {
              path
              title
            }
          }
        }
      }
    `}
    render={render(props)}
  />
)
