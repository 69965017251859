import React from 'react'

import {
  Flex,
  AspectRatio,
  GatsbyImage,
  GatsbyImageContainer,
  SVG,
} from 'system'
import { LogoInner } from 'src/components/LogoInner'

export const LogoBugImage = ({
  fill = 'teal',
  fillInner = 'white',
  imageFluid,
  ...props
}) => (
  <AspectRatio
    x={1}
    y={1}
    bg={imageFluid ? undefined : fill}
    borderRadius="50%"
    overflow="hidden"
    position="relative"
    {...props}
  >
    {imageFluid && (
      <GatsbyImageContainer
        height="100%"
        left={0}
        position="absolute"
        top={0}
        width="100%"
      >
        <GatsbyImage
          fluid={imageFluid}
          alt=""
          css={`
            object-fit: cover;
            pointer-events: none;
          `}
        />
      </GatsbyImageContainer>
    )}
    <Flex
      alignItems="center"
      height="100%"
      justifyContent="center"
      position="relative"
      width="100%"
    >
      <SVG svg={LogoInner} x={1} y={1} width={0.586} fill={fillInner} />
    </Flex>
  </AspectRatio>
)
