import React from 'react'

import { Subheading as SubheadingBase } from 'system'

export const Subheading = props => (
  <SubheadingBase
    color="sandDark"
    fontFamily="sansCond"
    fontSize="midLarge"
    fontWeight="bold"
    lineHeight="title"
    textStyle="smallCaps"
    {...props}
  />
)
