import React from 'react'

export const LogoInner = props => {
  return (
    <svg viewBox="0 0 127 127" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="m62.81 126.81-31.81-31.81h63.63z" />
      <path d="m112.21 77.71-48.91-48.9-48.9 48.9-14.4-14.4 63.3-63.31 63.31 63.3z" />
    </svg>
  )
}
