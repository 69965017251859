import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import VisuallyHidden from '@reach/visually-hidden'
import { get } from 'lodash/fp'

import { Box, Flex, SVG } from 'system'
import { LogoBug } from 'src/components/LogoBug'
import { LogoType } from 'src/components/LogoType'

const render = ({
  fill = 'black',
  fillBug,
  fillType,
  ...props
}) => queryData => {
  const settings = get('settings.childMarkdownRemark.frontmatter', queryData)

  return (
    <Box {...props}>
      <VisuallyHidden>{get('site_title', settings)}</VisuallyHidden>
      <Flex alignItems="center" justifyContent="space-between">
        <SVG svg={LogoBug} fill={fillBug || fill} width="18.25%" x={1} y={1} />
        <SVG
          svg={LogoType}
          fill={fillType || fill}
          width="77.77%"
          x={262}
          y={51}
        />
      </Flex>
    </Box>
  )
}

export const Logo = props => (
  <StaticQuery
    query={graphql`
      query {
        settings: file(relativePath: { eq: "settings.md" }) {
          childMarkdownRemark {
            frontmatter {
              site_title
            }
          }
        }
      }
    `}
    render={render(props)}
  />
)
