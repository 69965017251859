import React from 'react'

import { Box, Flex, Link } from 'system'
import { BoundedBox } from 'src/components/BoundedBox'
import { Heading } from 'src/components/Heading'
import { Logo } from 'src/components/Logo'

import { Mobile } from './Mobile'
import { Desktop } from './Desktop'

export const Header = props => (
  <BoundedBox
    as="header"
    bg="white"
    pb={[3, null, 4]}
    position="relative"
    zIndex={2}
    pt={[3, null, 4]}
    pl={[2, 4]}
    pr={[2, 4]}
    {...props}
  >
    <Flex alignItems="center" justifyContent="space-between">
      <Box
        width={['10rem', null, '15rem']}
        mr={4}
        position="relative"
        zIndex={2}
      >
        <Link to="/">
          <Heading as="h1">
            <Logo fillBug="teal" fillType="navy" />
          </Heading>
        </Link>
      </Box>
      <Mobile display={[null, null, 'none']} />
      <Desktop display={['none', null, 'flex']} />
    </Flex>
  </BoundedBox>
)
