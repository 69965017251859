import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { createGlobalStyle } from 'styled-components'
import Helmet from 'react-helmet'
import { get } from 'lodash/fp'

import { theme } from 'src/theme'
import { SystemProvider, Box, Text } from 'system'
import { Header } from 'src/components/Header'
import { Footer } from 'src/components/Footer'
import { Popup } from 'src/components/Popup'

import 'typeface-roboto'
import 'typeface-roboto-condensed'
import 'minireset.css'

const GlobalStyle = createGlobalStyle`
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
  }

  body {
    background-color: ${p => p.theme.colors.white};
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }

  a {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
  }
`

const render = ({ children }) => queryData => {
  const settings = get('settings.childMarkdownRemark.frontmatter', queryData)

  return (
    <>
      <Helmet
        titleTemplate={`%s – ${get('site_title', settings)}`}
        defaultTitle={get('site_title', settings)}
      >
        <html lang="en" />
        <meta name="description" content={get('site_description', settings)} />
        {/* Hawaii News Now tracking tag - AA 2020-05-06 */}
        <script
          async
          src="https://tag.simpli.fi/sifitag/0be35a90-7054-0138-727c-067f653fa718"
        />
      </Helmet>
      <SystemProvider theme={theme}>
        <>
          <GlobalStyle />
          <Text fontFamily="sans" fontSize="medium" color="navy">
            <Header />
            <Box as="main">{children}</Box>
            <Footer />
            <Popup />
          </Text>
        </>
      </SystemProvider>
    </>
  )
}

export const Layout = props => (
  <StaticQuery
    query={graphql`
      query {
        settings: file(relativePath: { eq: "settings.md" }) {
          childMarkdownRemark {
            frontmatter {
              site_title
              site_description
            }
          }
        }
      }
    `}
    render={render(props)}
  />
)
