import React from 'react'

import { Button as ButtonBase, Link } from 'system'

export const Button = props => (
  <ButtonBase
    as={Link}
    bg="teal"
    color="white"
    cursor="pointer"
    fontSize="tiny"
    fontWeight="bold"
    lineHeight={1}
    minWidth={['12rem', null, '14rem']}
    p={2}
    textAlign="center"
    textStyle="smallCaps"
    css={`
      transition-duration: ${p => p.theme.transition};
      transition-property: color, background-color;

      &:hover {
        color: ${p => p.theme.colors.navy};
        background-color: ${p => p.theme.colors.yellow};
      }
    `}
    {...props}
  />
)
