import React from 'react'

import { Box } from 'system'

export const TapaPatternFloating = props => (
    <Box
        backgroundImage='url("/images/tapa-reverse.svg")'
        backgroundRepeat="repeat-y"
        backgroundSize={['12rem auto', '18rem auto']}
        bottom={0}
        left={0}
        position="absolute"
        top={0}
        width={['12rem', '18rem']}
        css={`
            pointer-events: none;
        `}
        {...props}
    />
)
