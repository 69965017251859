import React from 'react'

export const IconTriangleRight = props => {
  return (
    <svg viewBox="0 0 10 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="m5 5 10 10h-20z"
        fill="#f7c677"
        fill-rule="evenodd"
        transform="matrix(0 1 -1 0 15 5)"
      />
    </svg>
  )
}
