import React from 'react'

export const LogoType = props => {
  return (
    <svg viewBox="0 0 262 51" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill-rule="evenodd">
        <path
          d="m13.59 20.08c-7.73 0-12.33-4.5-12.33-9.81s4.6-9.8 12.33-9.8 12.33 4.49 12.33 9.8-4.6 9.81-12.33 9.81zm0-15.7c-5 0-7.47 2.74-7.47 5.89s2.47 5.89 7.47 5.89 7.46-2.73 7.46-5.89-2.47-5.89-7.46-5.89z"
          fill-rule="nonzero"
        />
        <path
          d="m49.62 12.66 3.21 6.81h-4.94l-3-6.28h-9v6.28h-4.62v-18.4h15.93c1.607096-.05347412 3.1644866.56147863 4.301504 1.69849602s1.7519701 2.69440796 1.698496 4.30150398c.1586626 2.44602178-1.2918775 4.7109712-3.58 5.59zm-3.1-7.66h-10.52v4.18h10.55c1.1387114-.04178422 2.0491142-.96094092 2.08-2.1.0234528-.5638035-.1923593-1.11127893-.5942199-1.50742592-.4018607-.396147-.9523702-.60409767-1.5157801-.57257408z"
          fill-rule="nonzero"
        />
        <path d="m70.43 5v14.47h-4.6v-14.47h-8.28v-4h21.16v4z" />
        <path d="m102.15 19.47v-7.57h-13.3v7.57h-4.63v-18.4h4.63v6.86h13.3v-6.86h4.63v18.4z" />
        <path
          d="m124.18 20.08c-7.73 0-12.33-4.5-12.33-9.81s4.6-9.8 12.33-9.8 12.33 4.49 12.33 9.8-4.6 9.81-12.33 9.81zm0-15.7c-5 0-7.47 2.74-7.47 5.89s2.47 5.89 7.47 5.89 7.46-2.73 7.46-5.89-2.47-5.89-7.46-5.89z"
          fill-rule="nonzero"
        />
        <path
          d="m157.86 13.19h-11.23v6.28h-4.63v-18.4h15.86c1.607096-.05347412 3.164487.56147863 4.301504 1.69849602s1.75197 2.69440796 1.698496 4.30150398c.051979 1.61761173-.559461 3.1861848-1.692489 4.3418735-1.133028 1.1556888-2.689187 1.7980662-4.307511 1.7781265zm-.69-8.19h-10.54v4.18h10.54c1.138711-.04178422 2.049114-.96094092 2.08-2.1.022818-.55833318-.188988-1.10075094-.584118-1.49588168-.395131-.39513074-.937549-.60693636-1.495882-.58411832z"
          fill-rule="nonzero"
        />
        <path d="m169.65 19.47v-18.4h22.17v4h-17.54v3.31h15.43v3.58h-15.43v3.54h18.22v4z" />
        <path
          d="m210.75 19.47h-12.75v-18.4h12.72c7.41 0 11.62 4.13 11.62 9.2s-4.18 9.2-11.59 9.2zm-.1-14.56h-8v10.72h8c4.7 0 6.86-2.39 6.86-5.36s-2.16-5.36-6.86-5.36z"
          fill-rule="nonzero"
        />
        <path d="m227.42 19.47v-18.4h4.63v18.4z" />
        <path d="m250.47 20.08c-8 0-12.8-4.5-12.8-9.81s4.76-9.8 12.8-9.8c5.47 0 9.33 2.18 11.25 5.12l-4.15 2.11c-1-1.77-3.68-3.16-7.28-3.16-5.18 0-7.76 2.68-7.76 5.73s2.58 5.73 7.76 5.73c3.81 0 6.49-1.55 7.44-3.47l4.18 2.11c-1.82 3.12-5.79 5.44-11.44 5.44z" />
        <path
          d="m22.2 50.36-2.13-3.68h-13l-2.07 3.68h-4.94l10.57-18.36h5.91l10.57 18.4zm-8.6-15-4.21 7.31h8.41z"
          fill-rule="nonzero"
        />
        <path d="m40.78 51c-3.3895904-.1244362-6.7178632-.9411903-9.78-2.4l1.68-3.6c2.5451515 1.2838848 5.3324865 2.0164973 8.18 2.15 5.24.11 7.83-.88 7.87-2.41 0-1-.76-1.41-2.44-1.52-.76-.07-4.52-.31-8.56-.58s-7-2-6.88-5.33 4.7-6 12-5.84c3.367559.1335959 6.6822041.8826108 9.78 2.21l-1.47 3.6c-2.664782-1.1262138-5.5096504-1.7663092-8.4-1.89-4.18-.09-7.43.65-7.46 2.07 0 .92.76 1.36 2.26 1.47l9 .59c4.18.27 6.74 2 6.68 5.19-.17 3.63-4.15 6.49-12.46 6.29z" />
        <path
          d="m98 51c-7.73 0-12.33-4.49-12.33-9.8s4.6-9.81 12.33-9.81 12.33 4.5 12.33 9.81-4.64 9.8-12.33 9.8zm0-15.69c-5 0-7.46 2.73-7.46 5.89s2.46 5.85 7.46 5.85 7.47-2.74 7.47-5.89-2.47-5.89-7.47-5.89z"
          fill-rule="nonzero"
        />
        <path d="m128 51c-8 0-12.8-4.49-12.8-9.8s4.76-9.81 12.8-9.81c5.47 0 9.34 2.18 11.25 5.13l-4.15 2.1c-1-1.76-3.68-3.15-7.28-3.15-5.18 0-7.76 2.68-7.76 5.73s2.58 5.73 7.76 5.73c3.81 0 6.49-1.55 7.44-3.47l4.18 2.1c-1.87 3.09-5.83 5.44-11.44 5.44z" />
        <path d="m145.49 50.36v-18.36h4.63v18.4z" />
        <path
          d="m177.71 50.36-2.13-3.68h-13l-2.13 3.68h-4.91l10.61-18.36h5.91l10.57 18.4zm-8.59-15-4.21 7.31h8.41z"
          fill-rule="nonzero"
        />
        <path d="m196.53 35.93v14.43h-4.6v-14.43h-8.28v-3.93h21.16v4z" />
        <path d="m210.77 50.36v-18.36h22.16v4h-17.54v3.31h15.44v3.57h-15.44v3.58h18.22v4z" />
        <path d="m68.27 51c-3.3910018-.1307011-6.7193408-.9542778-9.78-2.42l1.68-3.58c2.544723 1.2850155 5.3323036 2.0176926 8.18 2.15 5.23.11 7.83-.88 7.87-2.41 0-1-.76-1.41-2.44-1.52-.77-.07-4.52-.31-8.56-.58s-7-2-6.88-5.33 4.7-6 11.95-5.84c3.3707373.1339521 6.6885983.8829278 9.79 2.21l-1.47 3.6c-2.664782-1.1262138-5.5096504-1.7663092-8.4-1.89-4.18-.09-7.43.65-7.46 2.07 0 .92.76 1.36 2.26 1.47l9 .59c4.18.27 6.74 2 6.67 5.19-.13 3.63-4.11 6.49-12.41 6.29z" />
        <path d="m248.57 51c-3.391002-.1307011-6.719341-.9542778-9.78-2.42l1.68-3.58c2.545151 1.2838848 5.332486 2.0164973 8.18 2.15 5.24.11 7.83-.88 7.87-2.41 0-1-.76-1.41-2.44-1.52-.76-.07-4.52-.31-8.56-.58s-6.95-2-6.88-5.33 4.7-6 12-5.84c3.367559.1335959 6.682204.8826108 9.78 2.21l-1.47 3.6c-2.664782-1.1262138-5.50965-1.7663092-8.4-1.89-4.18-.09-7.43.65-7.46 2.07 0 .92.76 1.36 2.26 1.47l8.95.59c4.18.27 6.74 2 6.68 5.19-.12 3.63-4.1 6.49-12.41 6.29z" />
      </g>
    </svg>
  )
}
