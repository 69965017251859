import React, { useState } from 'react'
import Hamburger from 'react-hamburger-menu'

import { theme } from 'src/theme'
import { Box, Flex, Text, Link } from 'system'

const NavItem = ({ to, children, ...props }) => (
  <Box as="li" mb={4} boxStyle="lastNoMargin">
    <Text color="teal" fontSize="xlarge" fontWeight="light" textAlign="center">
      <Link to={to}>{children}</Link>
    </Text>
  </Box>
)

export const Mobile = props => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Box {...props}>
      <Box
        cursor="pointer"
        height="1rem"
        position="relative"
        width="1.5rem"
        zIndex={2}
      >
        <Hamburger
          menuClicked={() => setIsOpen(!isOpen)}
          isOpen={isOpen}
          color={theme.colors.teal}
          width={24}
          height={16}
        />
      </Box>
      <Box
        as="nav"
        bg="white"
        width="100vw"
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        pt={6 + 8}
        pb={8}
        css={`
          box-shadow: 0 16px 48px rgba(0, 0, 0, 0.2),
            0 4px 8px rgba(0, 0, 0, 0.1);
          pointer-events: ${isOpen ? 'auto' : 'none'};
          opacity: ${isOpen ? 1 : 0};
          transition: opacity ${p => p.theme.transition};
        `}
      >
        <Flex
          as="ul"
          height="100%"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
        >
          <NavItem to="/services/">Services</NavItem>
          <NavItem to="/for-patients/">For Patients</NavItem>
          <NavItem to="/our-team/">Our Team</NavItem>
          <NavItem to="/locations/">Locations</NavItem>
          <NavItem to="/about/">About Us</NavItem>
        </Flex>
      </Box>
    </Box>
  )
}
