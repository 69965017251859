import React from 'react'

import { Heading as HeadingBase } from 'system'

export const Heading = props => (
  <HeadingBase
    fontSize={['large', 'xlarge']}
    fontWeight="light"
    lineHeight="title"
    {...props}
  />
)
